@import "../../settings";

.button {
  -webkit-appearance: none;
  background-color: $white;
  border: 1px solid #4c4c4c;
  border-radius: 2em;
  box-sizing: border-box;
  color: #4c4c4c;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-body;
  font-weight: $font-weight-semibold;
  line-height: 1;
  margin: 0;
  padding: 0.75em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  vertical-align: middle;

  &:hover {
    color: #000;
    background: #f7f7f7;
    border: 1px solid #333;
  }

  &:focus {
    box-shadow: 0 0 5px #ccc;
    outline: none;
  }

  &[disabled],
  &--disabled {
    background: #ccc;
    border-color: #ccc;
    color: #888;
    cursor: default;

    &:hover,
    &:focus {
      background: #ccc;
      border-color: #ccc;
      color: #888;
    }
  }

  + .button {
    margin-left: 1em;
  }

  &--primary {
    background-color: $green;
    border: none;
    color: $white;

    &:hover,
    &:focus {
      background-color: darken($green, 5%);
      border: none;
      color: $white;
    }
  }

  &--tiny {
    font-size: 12px;
    font-weight: bold;
  }

  &--small {
    font-size: 15px;
    font-weight: bold;
  }

  &--large {
    font-size: 18px;
    font-weight: $font-weight-semibold;
  }

  &--has-icon {
    padding-left: 3.2em;
    position: relative;
  }

  &__icon {
    position: absolute;
    left: 1em;
    top: 50%;
    margin-top: -0.6em;
    width: 1.2em;
    height: 1.2em;
  }

  &--reset {
    color: $error;
    padding: 0;
    border: 0;
    border-radius: 0;

    text-decoration: underline;
    font-weight: normal;
    font-size: 14px;
    vertical-align: baseline;

    &:hover,
    &:focus {
      border: 0;
      background-color: $white;
      box-shadow: none;
    }
  }
}
