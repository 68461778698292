@import "../../settings";

footer.site-footer {
  background: #315069;

  .adverse-event-banner {
    margin: 0;
    background-color: #346582;
    text-align: center;
    p {
      margin: 0;
      padding: 1.2em 0;
      color: #fff;
      font-weight: 600;
      @include xs {
        font-size: 13px;
        line-height: 16px;
      }
      a {
        color: #fff;

        .link__external {
          margin-bottom: 6px;
        }
      }
    }
  }

  .footer-content {
    padding: 80px 0;
    @include xs {
      padding: 60px 0;
      flex-direction: column;
    }
  }

  .info {
    p {
      margin: 0 0 1.5em 0;
      padding: 0;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: $white;
    }
  }

  .footer-box {
    margin: 0;
    padding: 90px 25px 10px 25px;
    background: #3f6079;

    p {
      margin: 0 0 1em 0;
      padding: 0;
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      color: $white;

      a {
        color: $white;
      }
    }
  }

  ul.footer-links {
    margin: 0 0 70px 0;
    padding: 0;
    list-style: none;
    text-align: right;
    @include xs {
      margin: 40px 0 10px 0;
      text-align: left;
    }
    li {
      margin: 0;
      padding: 0 16px;
      border-right: 1px solid $white;
      display: inline-block;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      color: $white;
      text-transform: uppercase;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
        border-right: none;
      }

      a {
        color: $white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footer-logo {
    position: relative;
    margin-bottom: 80px;
    a,
    div {
      position: absolute;
      right: 0;
      img {
        margin: 0;
        width: 163px;
        height: auto;
        max-width: 100%;
      }
    }
    div {
      right: auto;
    }
    @include xs {
      a {
        position: relative;
      }
    }
  }
}

.page.home footer.site-footer {
  .adverse-event-banner {
    display: none;
  }
}

.page.home.error footer.site-footer {
  .adverse-event-banner {
    display: block;
  }
}
