// Global styles
// react-flexbox-grid breakpoints: xs=576 sm=768 md=992 lg=1200

// The line below will be replaced with normalize.css rules appropriate for the
// browsers named in the "browserslist" key in package.json.
// See https://facebook.github.io/create-react-app/docs/adding-css-reset
@import-normalize;

@import "./settings";
@import "./utility";
@import "./typography";

html {
  background-color: #315069;
}

body {
  background-color: $white;
}

html,
body {
  cursor: default;
  font-family: $body-font-family;
  font-size: $font-size-body;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  border-top: 6px solid $green;
  color: $body-font-color;
}

.centered {
  margin: 0 auto;
}

.right-aligned {
  text-align: right;
}

// reset
.row {
  margin: 0px;
}
