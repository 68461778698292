@import "../../settings.scss";

$l2-nav-open-time: 0.2s;
$mobile-nav-open-time: 0.2s;

.nav {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.nav__open-button,
.nav__close-button,
.nav__link__chevron {
  display: none;
}

.nav__list {
  list-style: none;
  margin: 0;
  padding: 0;

  // Top-level nav list
  &--l1 {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }

  // Second-level nav list (dropdown)
  &--l2 {
    box-shadow: 3px 12px 25px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    opacity: 0;
    pointer-events: none;
    top: 50px;
    transition: box-shadow $l2-nav-open-time linear,
      opacity $l2-nav-open-time linear;
    z-index: $z-index-nav;

    @include md {
      top: 42px;
    }
  }
}

.nav__item {
  position: relative;
  line-height: 1.25;
  padding: 0;

  &--l1 {
    transition: box-shadow $l2-nav-open-time linear;
  }
}

.nav__link {
  border: none;
  color: #7c8d99;
  display: block;
  font-size: 15px;
  padding: 16px;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: #eef0f1;
    color: #4c4c4c;
  }

  &--l1 {
    border-bottom: 3px solid transparent;
    padding-bottom: 13px;
    color: #4c4c4c;
    text-align: center;
    transition: background-color $l2-nav-open-time linear,
      border-bottom-color $l2-nav-open-time linear;
    &--is-active {
      border-bottom-color: #b0b0b0;
    }
  }

  &--l2 {
    background-color: $white;
    width: 180px;
  }
}

// Superfish hover disclosure for subnav on desktop
.nav__item--l1.nav__item--subnav-visible,
.nav__item--l1.nav__item--has-subnav:hover {
  box-shadow: 3px 12px 25px 0px rgba(0, 0, 0, 0.3);

  .nav__link--l1 {
    background-color: #eef0f1;
    border-bottom-color: transparent;
  }

  .nav__list--l2 {
    opacity: 1;
    pointer-events: all;
  }
}

.nav__list__item--has-subnav:hover > .nav__list,
.nav__list--visible {
  display: block;
}

@include md {
  .nav__link--l1 {
    font-size: 14px;
    padding: 12px;
  }
}

@include sm {
  // MOBILE NAV

  .nav {
    background: $white;
    bottom: 0;
    overflow: hidden;
    left: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: left $mobile-nav-open-time linear,
      opacity $mobile-nav-open-time linear;
    width: 100%;
    z-index: $z-index-mobile-nav;

    &--visible {
      border-top: 6px solid $green;
      opacity: 1;
      padding-top: 64px;
      left: 0;
    }
  }

  .nav__open-button,
  .nav__close-button {
    appearance: none;
    display: block;
    background: transparent;
    border: none;
    height: 64px;
    width: 64px;
    padding: 18px;

    path {
      stroke: #b0b0b0;
    }

    rect {
      fill: #b0b0b0;
    }

    &:focus {
      outline: none;
    }
  }

  .nav__open-button {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav__close-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav__list--l1 {
    display: none;
  }

  .nav__list--l1 {
    display: none;

    .nav--visible & {
      display: block;
    }
  }

  .nav__list--l2 {
    box-shadow: none;
    max-height: 0;
    opacity: 1;
    overflow: hidden;
    position: static;
    visibility: visible;
    transition: max-height 0.3s linear;

    .nav__item--subnav-visible > & {
      max-height: 200px;
    }
  }

  .nav__link {
    text-align: right;
    padding-right: 25px;
    width: auto;

    &--l1 {
      border-bottom: none;

      &--is-active {
        border-bottom: none;
        border-right: 3px solid #b0b0b0;
      }

      .nav__link__chevron {
        display: inline-block;
        margin-left: 0.25em;
        transition: transform 0.1s linear;

        .nav__item--subnav-visible & {
          transform: rotate(180deg);
        }
      }
    }

    &--l2 {
      background: transparent;
      font-weight: normal;
    }
  }

  .nav__item--l1.nav__item--is-active {
    .nav__list--l2 {
      display: block;
    }
  }

  .nav__item--l1.nav__item--subnav-visible,
  .nav__item--l1.nav__item--has-subnav:hover,
  .nav__item--l1.nav__item--has-subnav.nav__item--is-active {
    background-color: #eef0f1;

    box-shadow: none;
  }
}
