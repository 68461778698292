@import "./settings";

// global typography styling
$heading-line-height: 1.3em;

h1,
.h1-style {
  color: $medium-blue;
  margin: 40px 0;
  padding: 0;
  font-family: proxima-nova;
  font-size: $font-size-h1;

  font-weight: 400;
  line-height: 1;
  @include sm {
    //font-size: $font-size-h1 * 0.8;
    font-size: 39px;
    margin: 10px 0;
  }
}

h2,
.h2-style {
  margin: 0 0 0.5em 0;
  padding: 0;
  font-size: 32px;
  $heading-line-height: 1.3em;
  font-weight: 400;
  @include xs {
    font-size: 24px;
  }
}

h3,
.h3-style {
  margin: 0 0 0.75em 0;
  padding: 0;
  font-size: $font-size-h3;
  font-weight: $font-weight-h3;
}

h4,
.h4-style {
  margin: 0 0 0.5em 0;
  padding: 0;
  font-size: 17px;
  $heading-line-height: 1.45em;
  font-weight: 700;
}

h5,
.h5-style {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 14px;
  $heading-line-height: 1.45em;
  font-weight: 700;
}

h6,
.h6-style {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 12px;
  $heading-line-height: 1.45em;
  font-weight: 700;
}

p,
.p-style {
  margin: 0 0 1.2em 0;
  padding: 0;
  font-size: 17px;
  line-height: 1.45em;
}

p.lede {
  font-size: 32px;
  line-height: 35px;

  @include sm {
    font-size: 24px;
    line-height: 28px;
  }
}

p.small,
.small {
  font-size: 14px;
}

p.warning {
  background-color: $light-grey;
  font-weight: bold;
  font-size: 17px;
  padding: 20px 15px;

  a {
    color: $green;
    transition: color linear 0.3s;
    &:hover {
      color: $dark-green;
    }
  }
}

ul,
ol {
  list-style-position: outside;
  margin: 0 0 20px 7px;
  padding: 0 0 0 1em;
  li {
    margin: 0;
    padding: 0 0 0 10px;
    font-size: 17px;
    line-height: 1.45em;
  }
}

a {
  color: $green;
  text-decoration: underline;
  transition: color linear 0.3s;
  &:hover,
  &:focus {
    color: $dark-green;
  }

  &.white {
    color: $white;
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.normal {
  font-weight: 400;
}
.semi-bold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}
.small-caps {
  font-variant: small-caps;
}

// colors
.white {
  color: $white;
}
.black {
  color: #000;
}
.body-text {
  color: $body-font-color;
}
.dark-blue {
  color: $dark-blue;
}
.medium-blue {
  color: $medium-blue;
}
.blue {
  color: $blue;
}
.grey {
  color: #4c4c4c;
}
.light-grey {
  color: $light-grey;
}
.green {
  color: $green;
}
.dark-green {
  color: $dark-green;
}

// some other utils
img {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: auto;
}

blockquote {
  background: #f7f7f7;
  margin: 0;
  padding: 35px;

  cite {
    color: #5b6770;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4;
  }
}
