@import "../../settings";

.modal {
  box-shadow: 3px 16px 48px rgba(0, 0, 0, 0.44);
  box-sizing: border-box;
  width: 50%;
  min-width: 630px;
  max-width: 750px;
  margin: 60px auto;
  background: $white;
  padding: 45px;
  position: relative;
  outline: none;

  h3 {
    font-size: 24px;
    font-weight: 600;
    @include xs {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &--skinny {
    max-width: 630px;
  }

  @include sm {
    margin: 10px;
    min-width: 0;
    max-width: 100%;
    padding: 35px;
    width: auto;
  }
}

// modal dialog styling
:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: $z-index-modal;
}

.modal__close {
  background-color: $white;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  &:before,
  &:after {
    position: absolute;
    content: " ";
    left: 14px;
    height: 30px;
    top: 0;
    width: 1px;
    background-color: $grey;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @include sm {
    right: 15px;
    top: 15px;
  }
}
