@import "../../settings.scss";

.page {
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 970px) {
    width: 100%;
  }

  .lede {
    font-size: 24px;
    line-height: 28px;
  }
}

.page__content {
  min-height: 400px;
}
