@import "../../settings.scss";

$hr-color-default: #b0b0b0;
$hr-color-white: rgba(255, 255, 255, 0.6);

.hr {
  border: 0;
  border-top: 3px solid $hr-color-default;
  margin: 20px 0;
  text-align: left;

  &--hairline {
    border-top-width: 1px;
  }

  &--skinny {
    width: 50px;
  }
  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  // XXX: In Safari, when an <a> contains an <hr>, the `color` property
  //      of the link overrides the border-top-color on the <hr>.
  //      Here we're manually forcing the `border-top-color` so Safari
  //      does the right thing.
  &,
  a:visited &,
  a:hover &,
  a:active & {
    border-top-color: #b0b0b0;
  }

  &--color-white {
    &,
    a:visited &,
    a:hover &,
    a:active & {
      border-top-color: $hr-color-white;
    }
  }
}
