@import "../../settings";

.site-header {
  padding: 15px 0 0 0;

  &__logos {
    position: relative;
    height: 64px;

    &__spark-medical,
    &__spark {
      position: absolute;
    }

    &__spark_medical {
      left: 0;
      top: 0;
      height: 64px;
    }

    &__spark {
      right: 12px;
      bottom: 0;
      height: 35px;
    }

    &__tagline {
      font-size: 14px;
      font-weight: 500;
      color: #5b6770;
      position: absolute;
      top: 8px;
      right: 12px;
      text-transform: uppercase;
    }
  }

  @include md {
  }

  @include sm {
    padding: 0;
    text-align: left;

    &__logos {
      height: 100px;

      &__spark-medical {
        position: static;
        display: inline-block;
        margin-top: 15px;
        img {
          height: 48px;
        }
      }
      &__spark {
        height: 18px;
        bottom: 12px;
        right: -8px;
      }
      &__tagline {
        top: auto;
        left: 0;
        bottom: 12px;
        padding: 10px 0 0 0;
        font-size: 11px;
      }
    }
  }

  @include xxs {
  }
}
