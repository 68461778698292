@import "../../settings";

.molecules {
  background-repeat: no-repeat;

  &--bottom {
    background-image: url(./images/molecules-bottom.svg);
    background-position: right bottom;
    background-position-x: calc(75% + (960px - 50vw));
    padding-bottom: 100px;
    @include md {
      background-position: center bottom;
      padding-bottom: 200px;
    }
  }

  &--left {
    background-image: url(./images/molecules-left.svg);
    background-position: -140px 120px;
    @include lg {
      background-position-x: calc((100vw - 1200px) - 140px);
    }
    @include md {
      background-image: none;
    }
  }
  &--right {
    background-image: url(./images/molecules-right.svg);
    background-position: right 150px;
    @include lg {
      background-position-x: calc((100vw - 1200px) - 140px);
    }
    @include md {
      background-image: none;
    }
  }
  &--center-right {
    background-image: url(./images/molecules-center-right.svg);
    background-position: right center;
    @include lg {
      background-position-x: calc((100vw - 1200px) - 140px);
    }
    @include md {
      background-image: none;
    }
  }
  &--center-right-2 {
    background-image: url(./images/molecules-right.svg);
    background-position: right center;
    @include lg {
      background-position-x: calc((100vw - 1200px) - 140px);
    }
    @include md {
      background-image: none;
    }
  }

  &--top-left {
    background-image: url(./images/molecules-top-left.svg);
    background-position: 0 100px;
    @include lg {
      background-position-x: calc((100vw - 1200px) - 120px);
    }

    @include md {
      background-image: none;
    }
  }

  &--top-right {
    background-image: url(./images/molecules-top-right.svg);
    background-position: right 80px;
    background-position-x: calc(100% + (370px - 20vw));
    @include lg {
      background-position-x: 730px;
    }
    @include md {
      background-image: none;
    }
  }

  &--bottom-left {
    background-image: url(./images/molecules-bottom-left.svg);
    background-position: left bottom;
    @include lg {
      background-position-x: calc((100vw - 1200px));
      background-position-y: calc(100% + (960px - 100vw) + 240px);
    }
    @include md {
      background: none;
    }
  }
}
