@import "../../settings";

.card {
  background-color: $white;
  border: none;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25);
  padding: 30px 40px;
  position: relative;

  &__title {
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 17px;
    line-height: 1.3em;
    font-weight: 500;

    @include sm {
      font-size: 20px;
    }
  }

  p {
    color: #4c4c4c;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;

    + p {
      margin-top: 10px;
    }
  }

  &--flat {
    border: 1px solid #00629b;
    box-shadow: none;
    text-align: center;
    @include sm {
      padding: 30px;
    }

    .card__title {
      font-size: $font-size-h3;
    }
    p {
      font-size: $font-size-body;
    }

    hr {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--small {
    padding: 20px;

    .card__title {
      font-size: $font-size-body;
      font-weight: 600;
    }
  }

  &--link {
    color: $black;
    display: block;
    padding-bottom: 60px;
    text-decoration: none;
    transition: box-shadow linear 0.2s, transform linear 0.2s,
      background-color linear 0.2s;

    &:hover,
    &:focus {
      box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.4);
      transform: scale(1.01);
    }

    &.card--flat {
      &:hover,
      &:focus {
        border-color: #ccc;
        outline: none;
      }
    }
  }

  .card__icon {
    margin: 0 0 10px 0;
    max-width: 60px;
  }

  &--flat .card__icon {
    margin: 0 auto;
    margin-bottom: 10px;
  }

  &__arrow {
    bottom: 25px;
    left: 50%;
    display: block;
    position: absolute;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    text-decoration: none;
    border: solid #43b02a;
    border-width: 0 1px 1px 0;
    transform: translateX(-25px) rotate(-45deg);

    .card--small & {
      width: 10px;
      height: 10px;
      transform: translateX(-10px) rotate(-45deg);
    }
  }
  &--slim {
    padding: 20px;
  }
}

.card--giant.card--flat {
  background-color: #fff;
  padding: 55px 50px;
  text-align: left;

  &.card--has-icon {
    padding-left: 130px;
  }

  .card__title {
    color: #444;
    font-size: 32px;
    line-height: 1;
    margin-bottom: 17px;
  }

  .card__icon {
    margin: 0;
    position: absolute;
    left: 37.5px;
    width: 55px;
  }

  p {
    font-size: 17px;
  }

  p + .button {
    margin-top: 1em;
  }
}
.card--giant.card--link {
  padding-bottom: 80px;
  .card__arrow {
    bottom: 35px;
  }
}

.card--strong {
  .card__title {
    font-weight: bold;
    text-align: center;
  }

  .card__arrow {
    border-width: 0 3px 3px 0;
  }
}

@include sm {
  .card--giant.card--flat {
    padding: 55px 50px;
    text-align: center;

    &.card--has-icon {
      padding-left: 50px;
    }

    .card__icon {
      position: static;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
}

.card {
  &--right {
    padding: 26px 20px;

    .card__title {
      font-size: 22px;
      line-height: 27px;
    }

    .card__arrow {
      left: auto;
      right: 15px;
      bottom: auto;
      top: 57px;

      border: solid $green;
      border-width: 0 1px 1px 0;
    }
  }
  &--hero {
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    .card__title {
      font-size: 32px;
      line-height: 35px;
    }
    .card__arrow {
      top: 50px;
      border: solid $white;
      border-width: 0 1px 1px 0;
    }
  }

  @mixin fg-light {
    color: $white;
    .card__title {
      color: $white;
    }
    @include hover {
      color: $white;
    }
    .hr.hr--skinny {
      width: auto;
      border-top: 1px solid $white;
    }
  }
  &--light-teal {
    background-color: change-color($light-teal, $alpha: 0.86);
    @include fg-light;
    @include hover {
      background-color: change-color($light-teal, $alpha: 1);
    }
  }
  &--dark-teal {
    background-color: change-color($dark-teal, $alpha: 0.86);
    @include fg-light;
    @include hover {
      background-color: change-color($dark-teal, $alpha: 1);
    }
  }
}

.offset-cards {
  margin-top: -60px;

  .card {
    box-sizing: border-box;
    height: 100%;

    @include md {
      margin: 0 auto;
    }
  }

  .row > div {
    @include md {
      padding: 8px 8px;
    }
  }
}
