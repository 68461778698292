@import "../../settings.scss";

.link--down-arrow {
  &::after {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: "";
    display: inline-block;
    width: 0.65em;
    height: 0.65em;
    left: 0.8em;
    position: relative;
    top: 0;
    transform: rotate(135deg);
    vertical-align: top;
    color: $dark-green;
  }
}

.link {
  &__external {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;
    margin-bottom: 3px;
  }
}
