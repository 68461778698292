// Grid tweaks applied on top of react-flexbox-grid

.container-fluid {
  margin: 0 auto;
  max-width: 960px;
}

.text-center {
  text-align: center;
}
